<template>
  <section class="landing-page">
    <Header />
    <MainInfo currentCard="Website Color Palette" />
    <Pricing />
    <ProductHunt />
    <Footer />

    <section class="hunt"></section>

    <footer class="footer"></footer>
  </section>
</template>

<script>
import Header from "@/components/landing/Header";
import MainInfo from "@/components/comparison/MainInfo";
import Pricing from "@/components/landing/Pricing";
import ProductHunt from "@/components/landing/ProductHunt";
import Footer from "@/components/landing/Footer";

export default {
  components: {
    Header,
    MainInfo,
    Pricing,
    ProductHunt,
    Footer,
  },
};
</script>
<style scoped>

* {
  color: #3d3d3d;
}

.landing-page {
  width: 100%;
  box-sizing: border-box;
}

</style>
