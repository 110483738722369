<template>
  <section class="comparison">
    <div class="comparison__content">
      <h1 class="comparison__title">
        Site Palette vs other browser extensions
      </h1>
      <h2 class="comparison__subtitle">
        Looking for the best color palette tool? Compare Site Palette with popular alternatives. Find out which tool offers superior palette generation, design exports, and integration with your favorite design apps. Make the best choice for your design needs.
      </h2>
      <ReadMoreCards />
    </div>
  </section>
</template>

<script>
import ReadMoreCards from "./ReadMoreCards";
export default {
  components: {
    ReadMoreCards
  }
};</script>

<style scoped>
* {
  color: #3d3d3d;
}

.comparison {
  background-color: #F5F8FA;
  width: 100%;
  padding: 20px 20px 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comparison__content {
  width: 100%;
  max-width: 1224px;
}

.comparison__title {
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -1.2px;
  color: #17191D;
  max-width: 720px;
  margin: 48px auto 24px;
  text-align: center;
}

.comparison__subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #17191D;
  max-width: 720px;
  margin: 0 auto 48px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .comparison {
    padding: 15px 16px 80px;
  }
  .comparison__title {
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -0.96px;
    margin-top: 60px;
  }
}
</style>
