<template>
  <section class="comparison">
    <div class="comparison__content">
      <h1 class="comparison__title">
        {{ competitorData.title }}
      </h1>
      <h2 class="comparison__subtitle">
        {{ competitorData.subtitle }}
      </h2>
      <GetExtBtn />
      <p class="comparison__disclamer">
        Free trial is available for new members.
      </p>
      <div class="comparison__info-cards">
        <div class="comparison__info-card">
          <h3 class="comparison__info-card-title">
            Site Palette
          </h3>
          <p class="comparison__info-card-text">
            {{ competitorData.descrOur }}
          </p>
        </div>
        <div class="comparison__info-card">
          <h3 class="comparison__info-card-title">
            {{ currentCard }}
          </h3>
          <p class="comparison__info-card-text">
            {{ competitorData.descrCompetitor }}
          </p>
        </div>
      </div>
      <div class="comparison__section">
        <h2 class="comparison__section-title">
          Comparison table
        </h2>
        <ComparisonTable :competitorTitle="currentCard"/>
      </div>
      <div class="comparison__section">
        <h2 class="comparison__section-title">
          More comparison
        </h2>
        <ReadMoreCards :currentCard="currentCard"/>  
      </div>
    </div>
  </section>
</template>

<script>
import GetExtBtn from "@/components/GetExtensionButton";
import ComparisonTable from "./ComparisonTable";
import ReadMoreCards from "./ReadMoreCards";
export default {
  components: {
    GetExtBtn, ComparisonTable, ReadMoreCards
  },
  props: {
    currentCard: {
      type: String,
      required: true
    }
  },
  data() {
    const colorData = {
      title: 'Site Palette vs. Color Palette',
      subtitle: 'Explore the differences between Site Palette and Color Palette, and discover why designers choose Site Palette for its advanced palette creation and export features.',
      descrOur: 'Site Palette is a versatile Chrome extension designed for creatives and developers to effortlessly generate color palettes from any webpage. With unlimited palette generations, it provides multiple palette formats, including Color Cube, Material Design, and Median Cut. Users can easily copy colors with a single click and download their palettes in various formats, such as SVG, Sketch Template, and Adobe Swatch. Additionally, Site Palette offers integration with Google Palette and Coolors for seamless palette management. Users can create a profile to store and share all their palettes, with a free trial available to explore the full range of features.',
      descrCompetitor: 'Color Palette is a free and easy-to-use browser extension designed for users looking for a quick and intuitive way to generate color palettes from web pages. Unlike other tools, Color Palette focuses on simplicity, allowing users to copy individual colors with a single click. However, it lacks advanced features such as the ability to download palettes or integrate with Google Palette and Coolors. This extension is ideal for those who want a straightforward, no-cost solution for basic color picking without the need for extra functionality.'
    }
    const extractData = {
      title: 'Site Palette vs. Extract Colors DevTool',
      subtitle: 'Uncover how Site Palette outperforms Extract Colors DevTool with advanced palette exporting, profile management, and seamless design tool integration.',
      descrOur: 'Site Palette is a powerful Chrome extension tailored for creatives and developers to quickly generate color palettes from any browser page. With the ability to create unlimited palettes, it offers various palette options, including Color Cube, Material Design, and Median Cut. Users can easily copy colors with a single click and download palettes in multiple formats, such as SVG, Sketch Template, and Adobe Swatch. The extension also allows integration with Google Palette and Coolors for efficient palette management. A personal profile stores all created palettes, enabling easy sharing, and a free trial lets users experience all its features.',
      descrCompetitor: "Extract Color DevTool is a versatile browser extension designed for users who need additional functionality beyond basic palette generation. It includes unique features like clean ads, a built-in color picker, a ruler for measuring on-screen elements, the ability to download an image along with the palette, and even the option to take screenshots of web pages. However, it lacks the ability to open palettes in Google Palette or Coolors and does not support downloads in Sketch, Adobe, or SVG formats. Additionally, it doesn't offer a profile feature for storing created palettes."
    }
    const websiteData = {
      title: 'Site Palette vs. Website Color Palette',
      subtitle: 'Explore the comparison between Site Palette and Website Color Palette, and find out why Site Palette is the better choice for professional designers.',
      descrOur: 'Site Palette is a powerful Chrome extension built for designers and developers to quickly generate color palettes from any browser page. It allows for unlimited palette generations and supports multiple formats, including Color Cube, Material Design, and Median Cut. With a simple click, users can copy colors and download palettes in formats like SVG, Sketch Template, and Adobe Swatch. The extension integrates with Google Palette and Coolors, making it easy to manage and explore palettes further. Users can also create a profile to save and share all their palettes, with a free trial available to experience all its features.',
      descrCompetitor: 'Website Color Palette is a basic browser extension that provides a simple solution for users looking to quickly generate color palettes from web pages. While it allows users to copy colors with a click and switch between formats such as HEX, HSL, and RGB, it lacks many advanced features offered by tools like Site Palette. There is no option to save palettes, download them in any format, or open them in Google Palette or Coolors, making it suitable for those who need minimal functionality without the extra tools for managing and sharing palettes.'
    }
    return {
      competitorData: this.currentCard === 'Color Palette' ? colorData : this.currentCard === 'Extract Colors DevTool' ? extractData : websiteData,
    };
  },
};
</script>

<style scoped>
* {
  color: #3d3d3d;
}

.comparison {
  background-color: #F5F8FA;
  width: 100%;
  padding: 20px 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comparison__content {
  width: 100%;
  max-width: 1224px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comparison__title {
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -1.2px;
  color: #17191D;
  max-width: 720px;
  margin: 48px auto 24px;
  text-align: center;
}

.comparison__subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #17191D;
  max-width: 720px;
  margin: 0 auto 24px;
  text-align: center;
}

.comparison__disclamer {
  margin: 14px auto 0;
  color: rgba(0, 0, 0, 0.50);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.comparison__info-cards {
  margin-top: 48px;
  width: 100%;
  display: flex;
  gap: 24px;
  margin-bottom: 120px;
}

.comparison__info-card {
  width: calc(100% / 2 - 24px);
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 12px;
  border: 1px solid rgba(41, 44, 46, 0.20);
}

.comparison__info-card-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.6px;
  color: #17191D;
  margin-bottom: 24px;
}

.comparison__info-card-text {
  font-family: "Avenir", Helvetica, Arial;
  color: #17191D;
  font-size: 18px;
  line-height: 26px;
}

.comparison__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 100%;
  margin-bottom: 120px;
}

.comparison__section-title {
  color: #17191D;
  font-size: 48px;
  font-weight: 600;
  line-height: 62px;
  letter-spacing: -0.96px;
}

@media screen and (max-width: 768px) {
  .comparison {
    padding: 15px 16px 0;
  }
  .comparison__content {
    align-items: stretch;
  }
  .comparison__content a {
    max-width: none;
  }
  .comparison__title {
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -0.96px;
    margin-top: 60px;
    max-width: none;
  }
  .comparison__subtitle {
    max-width: none;
  }
  .comparison__info-cards {
    flex-direction: column;
    margin-bottom: 80px;
  }
  .comparison__info-card {
    width: 100%;
    padding: 24px;
  }
  .comparison__info-card-title {
    font-size: 24px;
    line-height: 30px;
  }
  .comparison__info-card-text {
    font-size: 16px;
    line-height: 24px;
  }
  .comparison__section {
    gap: 40px;
    overflow: scroll;
    margin-bottom: 80px;
  }
  .comparison__section-title {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.72px;
  }
}
</style>
